<template>
    <div>
        <h4>Notifications</h4>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
}
</script>